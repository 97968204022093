import { format } from 'date-fns/fp';
import {
  always,
  applySpec,
  constructN,
  converge,
  filter,
  identity,
  ifElse,
  join,
  last,
  pathSatisfies,
  pipe,
  prop,
  propEq,
  propOr,
  split,
  unapply,
  when,
} from 'ramda';
import { isNotNilOrEmpty, lengthEq } from 'ramda-adjunct';

export const fromApiBankAccount = applySpec({
  accountNumber: pipe(
    converge(unapply(identity), [
      propOr(null, 'prefix'),
      propOr('', 'accountNumber'),
    ]),
    filter(isNotNilOrEmpty),
    join('-')
  ),
  bankCode: propOr('0100', 'bankCode'),
  isThirdParty: pathSatisfies(isNotNilOrEmpty, ['thirdParty', 'firstName']),
  thirdParty: pipe(
    propOr({}, 'thirdParty'),
    applySpec({
      firstName: propOr('', 'firstName'),
      lastName: propOr('', 'lastName'),
      birthDate: pipe(
        propOr(null, 'birthDate'),
        when(isNotNilOrEmpty, constructN(1, Date))
      ),
      citizenShip: propOr('203', 'citizenShip'),
      street: propOr('', 'street'),
      city: propOr('', 'city'),
      postalCode: propOr('', 'postalCode'),
      country: propOr('203', 'country'),
    })
  ),
});

export const toApiBankAccount = applySpec({
  accountNumber: pipe(prop('accountNumber'), split('-'), last),
  bankCode: prop('bankCode'),
  prefix: pipe(
    prop('accountNumber'),
    split('-'),
    ifElse(lengthEq(2), prop(0), always(undefined))
  ),
  thirdParty: ifElse(
    propEq('isThirdParty', true),
    pipe(
      prop('thirdParty'),
      applySpec({
        firstName: prop('firstName'),
        lastName: prop('lastName'),
        birthDate: pipe(prop('birthDate'), format('yyyy-MM-dd')),
        citizenShip: prop('citizenShip'),
        street: prop('street'),
        city: prop('city'),
        postalCode: prop('postalCode'),
        country: prop('country'),
      })
    ),
    always(undefined)
  ),
});
