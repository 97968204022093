/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Helmet } from 'react-helmet-async';

import { getGtmId } from './gtmUtils';
import scriptGTM from './scriptGTM';
import scriptAdobe from './scriptAdobe';

export function GTMInit() {
  const gtmId = getGtmId();

  if (gtmId === null) return null;

  return (
    <>
      <Helmet>
        <script>
          {scriptGTM(window, document, 'script', 'dataLayer', gtmId)}
        </script>
        <script>{scriptAdobe()}</script>
      </Helmet>
      <noscript>
        <iframe
          src={`https://www.googletagmanager.com/ns.html?id=${gtmId}`}
          height="0"
          width="0"
          style="display:none;visibility:hidden"
        ></iframe>
      </noscript>
    </>
  );
}
