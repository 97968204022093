/* eslint-disable no-unreachable */
import TagManager from 'react-gtm-module';

export function getGtmId() {
  if (window.location.href.indexOf('online.cardif.cz') > -1)
    return window.location.href.indexOf('preprod') > -1
      ? process.env.REACT_APP_GTM_TAG_PREPROD
      : process.env.REACT_APP_GTM_TAG_PROD;
  return null;
}

export function gtmInitizeWTagManager() {
  const gtmId = getGtmId();

  if (gtmId) {
    const gtmArgs = {
      gtmId,
      dataLayer: {
        userProject: 'ClaimsCZ',
        page: 'Welcome Page',
      },
      dataLayerName: 'adobeDataLayer',
      events: { event: 'page_loaded' },
    };

    TagManager.initialize(gtmArgs);
  }
}
