import { format } from 'date-fns/fp';
import {
  always,
  applySpec,
  constructN,
  defaultTo,
  ifElse,
  pipe,
  prop,
  propEq,
  propOr,
  propSatisfies,
  when,
} from 'ramda';
import { isNotNilOrEmpty } from 'ramda-adjunct';

export const fromApiHospitalization = applySpec({
  hospitalizationFrom: pipe(
    prop('hospitalizationFrom'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  hospitalizationTo: pipe(
    prop('hospitalizationTo'),
    when(isNotNilOrEmpty, constructN(1, Date)),
    defaultTo('')
  ),
  hasUntil: propSatisfies(isNotNilOrEmpty, 'hospitalizationTo'),
  reportedIncident: propOr('DISEASE', 'reportedIncident'),
  undergoneSpecialistExamination: propOr(
    false,
    'undergoneSpecialistExamination'
  ),
  undergoneImagingExamination: propOr(false, 'undergoneImagingExamination'),
  undergoneSurgeryOrHospitalised: propOr(
    false,
    'undergoneSurgeryOrHospitalised'
  ),
});

export const toApiHospitalization = applySpec({
  hospitalizationFrom: pipe(prop('hospitalizationFrom'), format('yyyy-MM-dd')),
  hospitalizationTo: ifElse(
    propEq('hasUntil', true),
    pipe(prop('hospitalizationTo'), format('yyyy-MM-dd')),
    always('')
  ),
  reportedIncident: prop('reportedIncident'),
  undergoneImagingExamination: prop('undergoneImagingExamination'),
  undergoneSpecialistExamination: prop('undergoneSpecialistExamination'),
  undergoneSurgeryOrHospitalised: prop('undergoneSurgeryOrHospitalised'),
});
