import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';

import './index.css';
import App from './App';
import { GTMInit } from './modules/gtm/gtmInit';

if (
  process.env.NODE_ENV !== 'development' &&
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__
) {
  window.__REACT_DEVTOOLS_GLOBAL_HOOK__.inject = () => {};
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <HelmetProvider>
    <GTMInit />
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </HelmetProvider>
);
