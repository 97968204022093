import { always, applySpec, evolve, ifElse, pipe, prop, unary } from 'ramda';
import { combineEpics, ofType } from 'redux-observable';
import { of } from 'rxjs';
import {
  switchMap,
  map as rxMap,
  catchError,
  withLatestFrom,
} from 'rxjs/operators';
import { format } from 'date-fns/fp';

import { fetchNolDetail } from '../../nol/detail/actions';
import { nolDetailSelector } from '../../nol/selectors';
import {
  updateBankAccountError,
  updateBankAccountSuccess,
  UPDATE_BANK_ACCOUNT,
  UPDATE_BANK_ACCOUNT_SUCCESS,
} from './actions';

const toApiData = pipe(
  applySpec({
    prefix: always(''),
    accountNumber: prop('accountNumber'),
    bankCode: prop('bankCode'),
    thirdParty: ifElse(
      prop('isThirdParty'),
      prop('thirdParty'),
      always(undefined)
    ),
  }),
  evolve({ thirdParty: { birthDate: format('yyyy-MM-dd') } })
);

const refetchDetailOnSuccessEpic = (action$, state$) =>
  action$.pipe(
    ofType(UPDATE_BANK_ACCOUNT_SUCCESS),
    withLatestFrom(state$),
    rxMap(([, state]) => nolDetailSelector(state)),
    rxMap(unary(pipe(prop('id'), unary(fetchNolDetail))))
  );

const updateBankAccountEpic = (action$, state$, { ajax }) =>
  action$.pipe(
    ofType(UPDATE_BANK_ACCOUNT),
    switchMap(({ payload }) =>
      ajax({
        useAuth: true,
        method: 'PUT',
        url: `/gateway-server/nol/bank-account/${payload.contractId}`,
        body: toApiData(payload.data),
      }).pipe(
        rxMap(() => updateBankAccountSuccess()),
        catchError((e) => of(updateBankAccountError(e)))
      )
    )
  );

export default combineEpics(refetchDetailOnSuccessEpic, updateBankAccountEpic);
