export default () => {
  window.adobeDataLayer = window.adobeDataLayer || [];
  window.adobeDataLayer.push({
    event: 'page_loaded',
    page: {
      language: document.getElementsByTagName('html')[0].getAttribute('lang'),
      title: document.title,
      v_url: document.URL,
    },
  });
};
